export const servicesData = [
  // {
  //   _id: 121,
  //   name: "Art Direction",
  //   line: "Bespoke Design,consistent visual strategy, that align with your brand identity and enhance user experience",
  //   line1:
  //     "Creating a consistent visual strategy that align with your brand identity and enhance user experience.",
  //   skills: [
  //     "Design principles",
  //     "Strategy",
  //     "Storytelling",
  //     "Concept",
  //     "Visual Consistency",
  //     "Project Management",
  //   ],
  // },
  {
    _id: 122,
    name: "Branding",
    line: "Your brand is your image, your story, your impact on the world! I will ensure it aligns with your goals, resonates with your audience, and positions you as a leader in your field.",
    line1:
      "Crafting visually compelling graphics and imagery to effectively communicate your brand's message.",
    skills: [
      "Logo Design",
      "Brand",
      "Identity",
      "Color schemes",
      "typography",
      "Photo Editing",
      "Icons",
      "Illustrations",
    ],
  },
  {
    _id: 123,
    name: "Design",
    line: "Design is not just about aesthetics; it’s about functionality, user-centered, with one purpose: solving a problem. Minimalism is the core of elegance, modernism, and Timeless Design.",
    line1:
      "Designing visually appealing and user-friendly interfaces, to deliver an exceptional and memorable user experience.",
    skills: [
      "UI/UX",
      "design process",
      "case study",
      "User-reseach",
      "User-needs",
      "wireframe",
      "Prototype",
      "User-testing",
    ],
  },
  {
    _id: 124,
    name: "Development",

    line: "Building and coding functional, responsive websites to ensure seamless performance and  usability, for optimal user experiences.",
    skills: [
      "front End",
      "HTML 5",
      "CSS 3",
      "SASS",
      "SEO",
      "JavaScript",
      "React",
      "Problem Solving",
    ],
  },
];
